export default function handleDataLayerOnClick(
	blockData,
	cardPosition,
	pathName,
	isLink
) {
	const re = /(\/videos|episodios)(\/[a-z0-9-]+\/)$/;
	if (
		typeof window.dataLayer !== "undefined" &&
		typeof window.sessionStorage !== "undefined"
	) {
		const contentName = blockData?.content_name?.includes(
			blockData?.contentTitle
		)
			? blockData.content_name
			: blockData?.content_name?.includes("Menu")
			? blockData?.content_name
			: (blockData.content_name += blockData?.contentTitle
					? blockData?.contentTitle
					: "");
		const eventData = {
			event: blockData?.event || "select_content",
			clickBloque: cardPosition ? parseInt(cardPosition) : undefined,
			content_name: contentName,
			colorBloque: blockData?.colorBloque,
			contenidosBloque: blockData?.contenidosBloque,
			content_type: isLink
				? blockData?.content_type
				: `Top-${cardPosition}-${blockData?.tituloBloque}>${blockData?.descripcionBloque}>${blockData?.channel}`,
			countWidget: blockData?.countWidget
				? `${blockData?.countWidget}`
				: undefined,
			descripcionBloque: blockData?.descripcionBloque,
			estiloBloque: blockData?.estiloBloque,
			eventAction: blockData?.eventAction,
			posicionBloque: Number.isInteger(parseInt(blockData?.posicionBloque))
				? parseInt(blockData?.posicionBloque)
				: undefined,
			section: pathName.replace(re, "/"),
			subBloque: blockData?.subBloque,
			tamanoBloque: blockData?.tamanoBloque,
			tipoBloque: blockData?.tipoBloque,
			tipoElemento: isLink ? "LinkText" : blockData?.tipoElemento,
			tituloBloque: blockData?.tituloBloque ?? undefined,
		};

		const sessionData = {
			prevPath: pathName,
			countWidget: blockData?.countWidget,
			prevBlock: blockData?.tipoBloque,
			prevElemntType: isLink ? "LinkText" : blockData?.tipoElemento,
			blockPosition: blockData?.posicionBloque,
		};
		window.sessionStorage.setItem("sessionData", JSON.stringify(sessionData));
		window.dataLayer.push(eventData);
	}
}
