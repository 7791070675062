'use client'
import { useEffect } from 'react';
import { usePathname } from 'next/navigation'

export default function useScrollToTop() {
  const path = usePathname();

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [path]);

  return null;
}
