"use client";

const PushNotification = () => {
	import(`@/app/site-tools/web-notifications/WebNotificationScript`).then(
		(js) => js.RenderWebNotificationScript()
	);
	return null;
};

export default PushNotification;
